@import "tailwindcss/base";
@import "./swiper.css";
@import "./typography.css";
@import "./map.css";
@import "./fullpage.css";

@import "tailwindcss/components";
@import "./projectsIndex.css";
@import "./layout.css";



@import "tailwindcss/utilities";