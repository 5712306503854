body {
  @apply antialiased;
}

.webkit-fill {
  height: 100vh;
  height: 100cqh;
}

.generalGrid {
  @apply grid grid-cols-12 gap-x-10 gap-y-10;
}

.text-grey {
  @apply text-slate-600/50;
}

.smallCats {
  @apply px-3 bg-[#F4FF94] py-2 text-xs;
}

media-player.video-cover video {
  @apply w-full h-full object-cover;
}

.cursor-arrow-left {
  cursor: url('../icons/leftArrow.svg'), auto;
}

.cursor-arrow-right {
  cursor: url('../icons/rightArrow.svg'), auto;
}

.scottishOffice, .londonOffice {
  @apply cursor-pointer;
}

/* purgecss start ignore */

strong {
  @apply font-medium;
}

.navHide {
  @apply !max-h-0;
}

.twic-blur {
  opacity: 0;
  will-change: opacity;
  transition: opacity 1s linear, transform 0.75s ease-out !important;
}

.twic-done {
  opacity: 1;
  transition: opacity 1s linear, transform 0.75s ease-out !important;
}

.twic-done ~ .swipePic {
  @apply -translate-y-full;
}

/* ! CKeditor custom */

.interview1 {
  @apply pl-16;
}

.interview2 {
  @apply pl-32;
}

/* purgecss end ignore */
