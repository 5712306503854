/* purgecss start ignore */

.gridjs-container {
  /* adjust list start position */
  @apply -mt-1 -mx-10;
}

.gridjs-head {
  /* hide the keyword search */
  display: none;
}

.gridjs-th-content {
  @apply text-left font-normal uppercase text-grey tracking-wider  text-xs;
}

th.gridjs-th-sort .gridjs-th-content {
  width: calc(100% - 20px);
  /* display: inline-block; */
  @apply pb-3 relative;
}

.gridjs-table {
  @apply border-separate;
  border-spacing: 2.5rem 0;
}

.gridjs-tbody .gridjs-tr {
  @apply border-separate;
}

.gridjs-tbody .gridjs-td {
  @apply py-2 border-t border-offBlack/30 border-dotted align-top;
}

.gridjs-th-sort {
  @apply relative;
}

button.gridjs-sort-asc {
  background-image: url('../icons/upArrow.svg');
  background-position-y: 100%;
  background-size: 8px;
}

button.gridjs-sort-desc {
  background-image: url('../icons/downArrow.svg');
  background-position-y: 100%;
  background-size: 8px;
}

button.gridjs-sort {
  float: right;
  height: 11px;
  width: 13px;
  /* display: inline-block; */
  background-repeat: no-repeat;
  @apply pt-1  translate-y-0.5 absolute top-0 right-0;
}
/* purgecss end ignore */
