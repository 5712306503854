@font-face {
  font-family: 'Marfa';
  src: url('../fonts/ABCMarfa-Medium-Trial.woff2') format('woff2'), url('../fonts/ABCMarfa-Medium-Trial.woff2') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Marfa';
  src: url('../fonts/ABCMarfa-MediumItalic-Trial.woff2') format('woff2'), url('../fonts/ABCMarfa-MediumItalic-Trial.woff2') format('woff');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Marfa';
  src: url('../fonts/ABCMarfa-Regular-Trial.woff2') format('woff2'), url('../fonts/ABCMarfa-Regular-Trial.woff2') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Marfa';
  src: url('../fonts/ABCMarfa-RegularItalic-Trial.woff2') format('woff2'), url('../fonts/ABCMarfa-RegularItalic-Trial.woff2') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Marfa';
  src: url('../fonts/ABCMarfa-Light-Trial.woff2') format('woff2'), url('../fonts/ABCMarfa-Light-Trial.woff2') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Marfa';
  src: url('../fonts/ABCMarfa-LightItalic-Trial.woff2') format('woff2'), url('../fonts/ABCMarfa-LightItalic-Trial.woff2') format('woff');
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Marfa';
  src: url('../fonts/ABCMarfa-Thin-Trial.woff2') format('woff2'), url('../fonts/ABCMarfa-Thin-Trial.woff2') format('woff');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Marfa';
  src: url('../fonts/ABCMarfa-ThinItalic-Trial.woff2') format('woff2'), url('../fonts/ABCMarfa-ThinItalic-Trial.woff2') format('woff');
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}
